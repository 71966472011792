<template>
  <div class="feedback-cycle-box">
    <van-nav-bar title="反馈周期配置" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="list" v-if="userInfo">
      <span>反馈周期配置：{{ userInfo.feedbackCycleDays }}天</span>
      <i @click="edit">修改</i>
    </div>
    <van-dialog v-model="show" show-cancel-button :before-close="beforeClose" @confirm="confirm">
      <div class="feedback-dialog-box">
        <div class="feedback-cycle-setting">
          <span>反馈周期配置：</span>
          <van-field v-model="digit" class="day" type="digit">
            <template #button>
              <img class="up" @click="up" src="../../assets/common/up.png" alt />
              <img class="down" @click="down" src="../../assets/common/down.png" alt />
            </template>
          </van-field>
          <span>天</span>
        </div>
        <div class="feedback-cycle-tip">反馈周期用于简历状态停留时，超过这个时间，将会通过邮件或短信的方式提醒，你应该及时处理了</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      show: false,
      digit: 0,
      userInfo: null,
    };
  },
  mounted() {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.digit = this.userInfo.feedbackCycleDays;
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    up() {
      if (this.digit < 30) {
        this.digit++;
      }
    },
    down() {
      if (this.digit > 0) {
        this.digit--;
      }
    },
    edit() {
      this.show = true;
    },
    beforeClose(action, done) {
      if (action === 'confirm') {
        done(false);
      } else {
        done();
      }
    },
    confirm() {
      if (!this.digit) {
        this.$toast('反馈周期未配置天数，请检查！');
        return
      } else if (this.digit <= 0 || this.digit > 30) {
        this.$toast('反馈周期未配置天数为正整数,且最大为30天');
        return
      } else {
        this.$http
          .post(
            '/selfhelpOfficialAccounts/users/feedback?feedbackCycle=' +
            this.digit,
            {
              feedbackCycle: this.digit,
            }
          )
          .then(async (res) => {
            if (res.code === 200) {
              let back = await this.$http.get(
                '/selfhelpOfficialAccounts/relation'
              );
              if (back.code === 200) {
                localStorage.setItem('userInfo', JSON.stringify(back.data));
                this.userInfo = back.data;
                this.show = false;
                this.$toast('设置成功');
              }
            } else {
              this.$toast(res.message || '设置失败');
            }
          });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.feedback-cycle-box {
  height: 100%;
  width: 100%;
  background: #ffffff;
  .list {
    height: 60px;
    line-height: 60px;
    display: flex;
    padding: 0 16px;
    justify-content: space-between;
    span {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    i {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #206cfe;
      font-style: normal;
    }
  }
  .feedback-dialog-box {
    padding: 25px 35px;
  }
  .feedback-cycle-setting {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    .day {
      padding: 0;
      width: 54px;
      margin-right: 10px;
      .van-field__body {
        position: relative;
      }
      ::v-deep .van-field__control {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #206cfe;
        height: 24px;
        width: 54px;
        border-radius: 2px;
        border: 1px solid #e5e5e5;
        padding: 2px 12px 2px 5px;
      }
      ::v-deep .van-field__button {
        padding-left: 0;
      }
      img {
        position: absolute;
      }
      .up {
        top: 2px;
        right: 1px;
        height: 10px;
        width: 10px;
      }
      .down {
        bottom: 2px;
        right: 1px;
        height: 10px;
        width: 10px;
      }
    }
  }
  .feedback-cycle-tip {
    margin-top: 9px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888888;
  }
}
</style>
